import { generateRandomID } from "../helpers/Calculations";

export class Flowmodoro {
  constructor(start, end, workTime, interruptions, breakTime) {
    this.start = start;
    this.end = end;
    this.workTime = workTime;
    this.interruptions = interruptions;
    this.breakTime = breakTime;
    this.id = generateRandomID();
  }
}
