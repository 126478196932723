import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { TasksProvider } from "./contexts/TasksContext";
import { FlowmodoroProvider } from "./contexts/FlowmodoroContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <TasksProvider>
      <FlowmodoroProvider>
        <App />
      </FlowmodoroProvider>
    </TasksProvider>
  // </React.StrictMode>
);
