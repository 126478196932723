import { createContext, useReducer, useEffect } from "react";
import { Flowmodoro } from "./Flowmodoro";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../contexts/db";

//CREATE CONTEXT -----------------------------------------------------
export const FlowmodoroContext = createContext();

//CREATE PROVIDER FOR CONTEXT ----------------------------------------
export function FlowmodoroProvider({ children }) {
  //new Flowmodoro object
  let clean_flowmo = new Flowmodoro(null, null, 0, 0, 0);

  //useReducer hook
  const [state, dispatch] = useReducer(flowmoReducer, {
    flowmodoro: clean_flowmo,
    isBreakTime: false,
    divisor: 5,
    alarmSound: "egg", //egg, beep, xylo
  });

  //check DB values for Settings
  const settingsDB = useLiveQuery(() => db.settings.toArray(), []);

  //When DB value changes & not null, change settings state
  useEffect(() => {
    if (settingsDB) {
      dispatch({
        type: "SET_SETTINGS",
        divisor: Number(settingsDB[0].divisor),
        alarm: settingsDB[0].alarmSound,
      });
    }
  }, [settingsDB]);

  return (
    <FlowmodoroContext.Provider value={{ ...state, dispatch }}>
      {children}
    </FlowmodoroContext.Provider>
  );
}

//ASYNC FUNCTIONS FOR INDEXED DB
const updateSettingsinDB = async (divisor, alarmSound) => {
  await db.settings.update(0, { divisor, alarmSound }); //settings index is always 0
};

//CREATE REDUCER TO UPDATE THE STATE IN PROVIDER --------------------
const flowmoReducer = (state, action) => {
  //state = {flowmodoro}
  switch (action.type) {
    case "CREATE_NEW_FLOWMO":
      let new_flowmo = new Flowmodoro(null, null, 0, 0, 0);
      return { ...state, flowmodoro: new_flowmo };
    case "SET_ISBREAK":
      return { ...state, isBreakTime: action.isBreakTime };
    case "DECREMENT_INT":
      state.flowmodoro.interruptions = action.num;
      return { ...state, flowmodoro: state.flowmodoro };
    case "INCREMENT_INT":
      state.flowmodoro.interruptions = action.num;
      return { ...state, flowmodoro: state.flowmodoro };
    case "SET_START_DATE":
      state.flowmodoro.start = new Date();
      return { ...state, flowmodoro: state.flowmodoro };
    case "SET_END_DATE":
      state.flowmodoro.end = new Date();
      return { ...state, flowmodoro: state.flowmodoro };
    case "SET_WORKTIME":
      state.flowmodoro.workTime = action.time;
      return { ...state, flowmodoro: state.flowmodoro };
    case "SET_BREAKTIME":
      state.flowmodoro.breakTime = action.breakTime;
      return { ...state, flowmodoro: state.flowmodoro };
    /*SETTINGS ------------------------------------------------------------- */
    /*User Change: */
    case "SET_DIVISOR":
    case "SET_ALARMSOUND":
      let newDivisor = action.divisor ? action.divisor : state.divisor;
      let newAlarmSound = action.alarm ? action.alarm : state.alarmSound;
      //update DB
      updateSettingsinDB(Number(newDivisor), newAlarmSound);
      //update State
      return { ...state, divisor: newDivisor, alarmSound: newAlarmSound };
    case "SET_SETTINGS":
      return {
        ...state,
        divisor: action.divisor,
        alarmSound: action.alarm,
      };
    default:
      return state;
  }
};
