import React from "react";
import { useTasksContext } from "../hooks/useTasksContext";
import { convertTimeToHrsMins } from "../helpers/Calculations";
import CSVDownload from "./CSVDownload";

export default function Table() {
  const { tasks } = useTasksContext();

  function addLeadingZero(num) {
    return String(num).padStart(2, "0");
  }

  return (
    <div className="flexCol">
      <div className="table">
        <table>
          <tbody>
            <tr>
              <th>Task</th>
              <th>Start</th>
              <th>End</th>
              <th>Work</th>
              <th>Break</th>
              <th>Int.</th>
            </tr>

            {tasks &&
              tasks.map((task) => {
                let returnRow = [];

                if (task.flowmodoros) {
                  task.flowmodoros.forEach((flowmo) => {
                    let row = (
                      <tr key={flowmo.id}>
                        <td>
                          <div className="scrollable">{task.taskName}</div>
                        </td>
                        <td>
                          {flowmo.start.getMonth() +
                            1 +
                            "/" +
                            flowmo.start.getDate() +
                            " - "}
                          {addLeadingZero(flowmo.start.getHours()) +
                            ":" +
                            addLeadingZero(flowmo.start.getMinutes())}
                        </td>
                        <td>
                          {flowmo.end.getMonth() +
                            1 +
                            "/" +
                            flowmo.end.getDate() +
                            " - "}
                          {addLeadingZero(flowmo.end.getHours()) +
                            ":" +
                            addLeadingZero(flowmo.end.getMinutes())}
                        </td>
                        <td>{convertTimeToHrsMins(flowmo.workTime)}</td>
                        <td>{convertTimeToHrsMins(flowmo.breakTime)}</td>
                        <td>{flowmo.interruptions}</td>
                      </tr>
                    );
                    returnRow.push(row);
                  });
                }
                return returnRow;
              })}
          </tbody>
        </table>
      </div>
      <CSVDownload tasks={tasks}>
        <div className="flexRow csv-button">
          <h3>CSV</h3>
          <span className="material-symbols-outlined">download</span>
        </div>
      </CSVDownload>
    </div>
  );
}
