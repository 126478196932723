import React from "react";
import { useFlowmodoroContext } from "../hooks/useFlowmodoroContext";

export default function EstimatedBreak() {
  const { flowmodoro } = useFlowmodoroContext();
  var breaktime = flowmodoro.breakTime;

  return (
    <div className="white-border estimated-break flexCol">
      <h3>
        Estimated <br />
        Break Time:
      </h3>
      <h2 className="break-time">
        <strong>{breaktime}</strong>
        {" min"}
      </h2>
    </div>
  );
}
