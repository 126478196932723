import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <header>
      <div className="navbar">
        <div className="flexRow">
          <Link to="/">
            <h1>flowmodoro</h1>
          </Link>
        </div>
        <div className="line"></div>
      </div>
    </header>
  );
}
