export const convertTimeToHrsMins = (time) => {
  let str = "";
  time = Math.floor(time / 60000); // divide ms and secs
  let mins = time % 60;
  str = mins + "min";

  time = Math.floor(time / 60);
  let hours = time % 60;
  if (hours > 0) {
    str = hours + "hr " + str;
  }
  return str;
};

export const generateRandomID = () => {
  return Math.random().toString(16).slice(2);
};
