import React, { useState, useEffect } from "react";
import { useTasksContext } from "../hooks/useTasksContext";
import { useIsMount } from "../hooks/useIsMount";
import { usePrevious } from "../hooks/usePrevious";
import TaskTable from "./TaskTable";

export default function Tasks({ task, index }) {
  const { currentTask, dispatch } = useTasksContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  let name = task.taskName;
  let isDone = task.isDone;
  let flowmodoros = task.flowmodoros;

  //useIsMount hook
  const isMount = useIsMount();
  //usePrevious hook to get prev value
  const prevFlowmodoros = usePrevious(flowmodoros);

  useEffect(() => {
    if (isMount) return;

    //if added flowmo to list
    if (prevFlowmodoros && prevFlowmodoros.length >= flowmodoros.length) {
      //do nothing if flowmo was deleted
    } else {
      setIsAdded(true);
      setTimeout(() => {
        setIsAdded(false);
      }, 2000);
    }
  }, [flowmodoros.length]);

  const handleChecked = () => {
    dispatch({ type: "TOGGLE_TASK_ISDONE", index: index, done: !isDone });
    if (isDone) {
      setIsExpanded(false);
    }
  };
  const handleTaskSelected = () => {
    if (!isDone) {
      if (currentTask !== index) {
        dispatch({ type: "SELECT_TASK", index: index });
      }
    }
  };
  const handleExpanded = () => {
    setIsExpanded(!isExpanded); //toggle expanded
    handleTaskSelected();
  };
  const handleDelete = () => {
    dispatch({ type: "DELETE_TASK", index: index });
  };
  function handleChange(event) {
    dispatch({ type: "SET_TASK_NAME", index: index, name: event.target.value });
  }

  return (
    <div
      className={
        "task-card flexCol " +
        (isDone ? "done " : " ") +
        (currentTask === index && !isDone ? "selected " : " ") +
        (isAdded ? "flowmo-added-animation" : " ")
      }
    >
      <div className="task flexRow">
        {isDone ? (
          <span
            className="material-symbols-outlined checkbox btn"
            onClick={handleChecked}
          >
            check_box
          </span>
        ) : (
          <span
            className="material-symbols-outlined checkbox btn"
            onClick={handleChecked}
          >
            check_box_outline_blank
          </span>
        )}
        <div className="task-name" onClick={handleTaskSelected}>
          <input
            className="input-disabled"
            type="text"
            name="task-name"
            value={name ? name : ""}
            onChange={handleChange}
            placeholder="Click here to edit Task Name"
          />
        </div>
        {isExpanded ? (
          <i className="expand-btn" title="Expand Less">
            <span
              className="material-symbols-outlined btn"
              onClick={handleExpanded}
            >
              expand_less
            </span>
          </i>
        ) : (
          <i className="expand-btn" title="Expand More">
            <span
              className="material-symbols-outlined btn"
              onClick={handleExpanded}
            >
              expand_more
            </span>
          </i>
        )}
        <i className="delete-btn" title="Delete Task">
          <span
            className="material-symbols-outlined btn"
            onClick={handleDelete}
          >
            delete
          </span>
        </i>
      </div>
      {isExpanded && (
        <>
          <div className="task-line"></div>
          <div onClick={handleTaskSelected}>
            <TaskTable task={task} taskIndex={index} />
          </div>
        </>
      )}
    </div>
  );
}
