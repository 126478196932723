import { TasksContext } from "../contexts/TasksContext";
import { useContext } from "react";

export const useTasksContext = () => {
  const context = useContext(TasksContext);

  //check if we are in the scope of the context
  if (!context) {
    throw Error("useTasksContext must be used inside a TasksProvider");
  }
  return context;
};
