import React, { useState, useEffect } from "react";
import { useStopwatch, useTimer } from "react-use-precision-timer";
import { useTasksContext } from "../hooks/useTasksContext";
import { useFlowmodoroContext } from "../hooks/useFlowmodoroContext";
import { useIsMount } from "../hooks/useIsMount";
import { useSound } from "use-sound";

export default function Timer() {
  //Contexts
  const {
    flowmodoro,
    isBreakTime,
    divisor,
    alarmSound,
    dispatch: flowmoDispatch,
  } = useFlowmodoroContext();
  const { dispatch: taskDispatch } = useTasksContext();
  //OnMount hook
  const isMount = useIsMount();
  //Use State
  const [stopwatchStarted, setStopwatchStarted] = useState(false);
  const [stopwatchPaused, setStopwatchPaused] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [renderTime, setRenderTime] = useState(new Date().getTime());
  //Timer States
  const [delay, setDelay] = useState(1000);
  const [runOnce, setRunOnce] = useState(true);
  const [startImmediately, setStartImmediately] = useState(false);
  const timerCallback = () => {
    //ring bell
    ringBell();
    setTimerStarted(false);
    //reset timer
    timer.stop();
    //pause for some time before switching screen
    setTimeout(() => {
      flowmoDispatch({ type: "SET_ISBREAK", isBreakTime: false });
    }, 800);
  };
  //Timer/Stopwatch
  const stopwatch = useStopwatch();
  const timer = useTimer({
    delay,
    callback: timerCallback,
    runOnce,
    startImmediately,
  });

  const getSoundFilePath = (alarmStr) => {
    switch (alarmStr) {
      case "egg":
        return "audio/eggTimer.mp3";
      case "beep":
        return "audio/electronicBeep.mp3";
      case "xylo":
        return "audio/xylophone.mp3";
      default:
        return "audio/eggTimer.mp3";
    }
  };

  //Timer Sound
  const [ringBell] = useSound(getSoundFilePath(alarmSound), { volume: 0.5 });

  //stopwatch on update
  useEffect(() => {
    if (isMount) return;
    if (stopwatchStarted) {
      stopwatch.start();
      //set Start Date
      flowmoDispatch({ type: "SET_START_DATE" });
    } else if (!stopwatchPaused) {
      stopwatch.pause();
      //set End Date
      flowmoDispatch({ type: "SET_END_DATE" });
      //set Work Time
      flowmoDispatch({
        type: "SET_WORKTIME",
        time: stopwatch.getElapsedRunningTime(),
      });

      //setBreakTime
      flowmoDispatch({ type: "SET_ISBREAK", isBreakTime: true });
      //setTimer
      var actualBreakTime = stopwatch.getElapsedRunningTime() / divisor;
      setDelay(actualBreakTime); //breaktime
      setStartImmediately(true);
      setTimerStarted(true);

      //set true break time to flowmo
      flowmoDispatch({ type: "SET_BREAKTIME", breakTime: actualBreakTime });
      //add flowmodoro to flowmodoro array of selected task
      taskDispatch({ type: "SET_FLOWMODORO", flowmodoro });
      //create new flowmodoro object
      flowmoDispatch({ type: "CREATE_NEW_FLOWMO" });
      //reset stopwatch
      stopwatch.stop();
    }
    setStopwatchPaused(false);
  }, [stopwatchStarted]);

  useEffect(() => {
    if (timerStarted) {
      timer.start();
    }
  }, [delay, timerStarted, startImmediately]);

  useEffect(() => {
    const timeout = setTimeout(() => setRenderTime(new Date().getTime()), 10);
    return () => {
      clearTimeout(timeout);
    };
  });

  const handleOnClick = () => {
    setStopwatchStarted(!stopwatchStarted);
  };

  return (
    <div className="stopwatch-container">
      <div className="timer white-border">
        <div className="time flexCol">
          {!isBreakTime ? (
            <>
              {/* STOPWATCH */}
              <Time time={stopwatch.getElapsedRunningTime()}></Time>
              <div className="flexRow">
                <button onClick={handleOnClick}>
                  {stopwatchStarted ? "stop" : "start"}
                </button>
                <button
                  onClick={() => {
                    stopwatch.stop();
                    setStopwatchPaused(true);
                    setStopwatchStarted(false);
                  }}
                >
                  reset
                </button>
              </div>
            </>
          ) : (
            <>
              {/* COUNTDOWN TIMER */}
              {timer && <Time time={timer.getRemainingTime()}></Time>}
              <button
                onClick={() => {
                  setTimerStarted(false);

                  //record the cut-short break time
                  let breakTaken = timer.getElapsedRunningTime();
                  //set the true break time in flowmo of task
                  taskDispatch({
                    type: "UPDATE_FLOWMO_BREAKTIME",
                    breakTime: breakTaken,
                  });
                  //reset timer
                  timer.stop();
                  flowmoDispatch({ type: "SET_ISBREAK", isBreakTime: false });
                }}
              >
                skip
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function Time(props) {
  const [mins, setMins] = useState(0);
  const {
    isBreakTime,
    divisor,
    dispatch: flowmoDispatch,
  } = useFlowmodoroContext();

  let { str: timeStr, centisecs } = getTime(props.time);

  useEffect(() => {
    if (!isBreakTime) {
      //every minute
      let breakTime = Math.floor(props.time / (60000 * divisor));
      flowmoDispatch({ type: "SET_BREAKTIME", breakTime });
    }
  }, [mins, divisor]);

  function addLeadingZero(num) {
    return String(num).padStart(2, "0");
  }

  function getTime(time) {
    let str = "";
    let millisecs = time % 1000;
    let centisecs = Math.floor(millisecs / 10);
    time = Math.floor(time / 1000);
    let secs = time % 60;
    time = Math.floor(time / 60);
    if (time % 60 !== mins) setMins(time % 60); //only set mins if it changed
    time = Math.floor(time / 60);
    let hours = time % 60;
    if (hours > 0) {
      str = addLeadingZero(hours) + ":";
    }
    str = str + addLeadingZero(mins) + ":" + addLeadingZero(secs);

    return { str, centisecs };
  }

  return (
    <div className="timeText">
      <h1>{timeStr}</h1>
      <h2>{":" + addLeadingZero(centisecs)}</h2>
    </div>
  );
}
