import React, { useEffect, useState } from "react";

/* wrapper to download csv file */
export default function CSVDownload({ children, tasks }) {
  const [csvValues, setCsvValues] = useState("");
  const header =
    "Task Name, Flowmo %23, Start (MM/DD/YYYY h:mm), End (MM/DD/YYYY h:mm), Work Time (mins), Break Time (mins), Interruptions\n"; //%23 = #

  const convertDateToCSVString = (dateObj) => {
    let dateString = "";
    dateString = dateString.concat(
      (dateObj.getMonth() + 1).toString(),
      "/",
      dateObj.getDate().toString(),
      "/",
      dateObj.getFullYear().toString(),
      " ",
      dateObj.getHours().toString(),
      ":",
      dateObj.getMinutes().toString()
    );
    return dateString;
  };

  useEffect(() => {
    let csvString = "";

    tasks &&
      tasks.forEach((task) => {
        task.flowmodoros.forEach((flowmo, index) => {
          csvString = csvString.concat(
            [
              task.taskName,
              index,
              convertDateToCSVString(flowmo.start),
              convertDateToCSVString(flowmo.end),
              Math.floor(flowmo.workTime / 60000),
              Math.floor(flowmo.breakTime / 60000),
              flowmo.interruptions,
            ].join(","),
            "\n"
          );
        });
      });
    setCsvValues(csvString);
  }, [tasks]);

  return (
    <div className="CSV-download">
      <a
        href={"data:text/csv;charset=utf-8," + header + csvValues}
        download="flowmodoros"
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    </div>
  );
}
