import React from "react";
import { useFlowmodoroContext } from "../hooks/useFlowmodoroContext";

export default function Interruptions() {
  const { flowmodoro, dispatch } = useFlowmodoroContext();
  var interruptions = flowmodoro.interruptions;

  const decrement = () => {
    if (flowmodoro.interruptions - 1 < 0) return;
    dispatch({ type: "DECREMENT_INT", num: flowmodoro.interruptions - 1 });
  };
  const increment = () => {
    dispatch({ type: "INCREMENT_INT", num: flowmodoro.interruptions + 1 });
  };

  return (
    <div className="interruptions-box white-border">
      <h3>Interruptions</h3>
      <div className="flexRow counter">
        <span className="material-symbols-outlined btn" onClick={decrement}>
          remove
        </span>
        <h1>{interruptions}</h1>
        <span className="material-symbols-outlined btn" onClick={increment}>
          add
        </span>
      </div>
    </div>
  );
}
