import { generateRandomID } from "../helpers/Calculations";

export class Task {
  constructor(taskName, isDone, flowmodoros) {
    this.taskName = taskName;
    this.isDone = isDone;
    this.flowmodoros = flowmodoros;
    this.id = generateRandomID();
  }

  static getTotalWorkTime(flowmos) {
    if (!flowmos.length === 0) {
      return flowmos.reduce((total, flowmo) => total + flowmo.workTime, 0);
    } else {
      return 0;
    }
  }
  static getTotalBreakTime(flowmos) {
    if (!flowmos.length === 0) {
      return flowmos.reduce((total, flowmo) => total + flowmo.breakTime, 0);
    } else {
      return 0;
    }
  }
  static getTotalInterruptions(flowmos) {
    if (!flowmos.length === 0) {
      return flowmos.reduce((total, flowmo) => total + flowmo.interruptions, 0);
    } else {
      return 0;
    }
  }
}
