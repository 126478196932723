import { createContext, useReducer, useEffect } from "react";
import { Task } from "./Task";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../contexts/db";

//CREATE CONTEXT -----------------------------------------------------
export const TasksContext = createContext();

//CREATE PROVIDER FOR CONTEXT ----------------------------------------
export function TasksProvider({ children }) {
  //useReducer hook
  const [state, dispatch] = useReducer(tasksReducer, {
    tasks: [],
    currentTask: -1,
    lastAddedTaskFlowmo: null,
  });

  //check DB values for Tasks
  const tasksDB = useLiveQuery(() => db.tasks.toArray(), []);

  //When DB value changes & not null, change tasks state
  useEffect(() => {
    if (tasksDB) {
      let tasks = tasksDB.map((t) => t.task);
      dispatch({
        type: "SET_TASKS",
        tasks: tasks,
      });
    }
  }, [tasksDB]);

  return (
    <TasksContext.Provider value={{ ...state, dispatch }}>
      {children}
    </TasksContext.Provider>
  );
}

//ASYNC FUNCTIONS FOR INDEXED DB
//ADD
const addTaskinDB = async (task) => {
  await db.tasks.add({ task, id: task.id });
};
//UPDATE
const updateTaskNameinDB = async (name, taskID) => {
  db.tasks.update(taskID, {
    "task.taskName": name,
  });
};
const updateTaskDoneinDB = async (isDone, taskID) => {
  db.tasks.update(taskID, {
    "task.isDone": isDone,
  });
};
const updateTaskSetFlowmosinDB = async (flowmosArr, taskID) => {
  db.tasks.update(taskID, {
    "task.flowmodoros": flowmosArr,
  });
};
//DELETE
const deleteTaskinDB = async (taskID) => {
  await db.tasks.delete(taskID);
};

//CREATE REDUCER TO UPDATE THE STATE IN PROVIDER --------------------
const tasksReducer = (state, action) => {
  switch (action.type) {
    case "SET_TASKS":
      return { ...state, tasks: action.tasks };

    case "ADD_TASK":
      let new_task = new Task("", false, []);
      let nextIndex = state.tasks.length; //previous length btw
      //update DB
      addTaskinDB(new_task);
      //update State
      return {
        ...state,
        tasks: [...state.tasks, new_task],
        currentTask: nextIndex,
      }; //currentTask is last index, the new added task

    case "SET_TASK_NAME":
      state.tasks[action.index].taskName = action.name;
      //update DB
      updateTaskNameinDB(action.name, state.tasks[action.index].id);
      return { ...state, tasks: [...state.tasks], currentTask: action.index };

    case "TOGGLE_TASK_ISDONE":
      state.tasks[action.index].isDone = action.done;
      //updateDB
      updateTaskDoneinDB(action.done, state.tasks[action.index].id);

      //update currentTask index
      let newIndex = state.currentTask;
      //if current task is this task
      if (newIndex === action.index) {
        newIndex++; //select next task
        //if no next task, select no task
        if (newIndex >= state.tasks.length) newIndex = -1;
        else {
          //check for next not finished task
          while (state.tasks[newIndex].isDone) {
            newIndex++;
            if (newIndex >= state.tasks.length) {
              newIndex = -1;
              break;
            }
          }
        }
      }

      //update State
      return { ...state, tasks: [...state.tasks], currentTask: newIndex };

    case "SET_FLOWMODORO":
      let currentlySelectedTask = state.tasks[state.currentTask];

      if (state.currentTask < 0 || state.currentTask >= state.tasks.length) {
        console.log("No Tasks Specified. Cannot save this flowmodoro!");
        return { ...state };
      }
      //add flowmo
      currentlySelectedTask.flowmodoros = [
        ...currentlySelectedTask.flowmodoros,
        action.flowmodoro,
      ];
      //update DB
      updateTaskSetFlowmosinDB(
        currentlySelectedTask.flowmodoros,
        currentlySelectedTask.id
      );
      //update State
      return {
        ...state,
        lastAddedTaskFlowmo: {
          taskID: currentlySelectedTask.id,
          flowmoID: action.flowmodoro.id,
        },
      };

    case "UPDATE_FLOWMO_BREAKTIME":
      //if there was a last added Task/Flowmo,
      if (state.lastAddedTaskFlowmo != null) {
        let { taskID, flowmoID } = state.lastAddedTaskFlowmo;

        //check if task still exists
        let taskFound = state.tasks.filter((task) => {
          return task.id === taskID;
        });

        if (taskFound.length === 1) {
          //check if flowmo still exists
          let flowmoFound = taskFound[0].flowmodoros.filter((flow) => {
            return flow.id === flowmoID;
          });
          if (flowmoFound.length === 1) {
            //update the break time
            flowmoFound[0].breakTime = action.breakTime;
            //update DB
            updateTaskSetFlowmosinDB(taskFound[0].flowmodoros, taskFound[0].id);
          } else
            console.log("Could not find flowmo to update Skipped BreakTime");
        } else console.log("Could not find task to update Skipped BreakTime");
      }
      return { ...state };

    case "DELETE_FLOWMO":
      var currTasksFlowmos = state.tasks[action.taskIndex].flowmodoros;
      let remainingFlowmos = currTasksFlowmos.filter((flowmo, index) => {
        return index !== action.flowmoIndex;
      });

      state.tasks[action.taskIndex].flowmodoros = remainingFlowmos;
      //update DB
      updateTaskSetFlowmosinDB(
        remainingFlowmos,
        state.tasks[action.taskIndex].id
      );
      return { ...state };

    case "DELETE_TASK":
      //delete in DB
      deleteTaskinDB(state.tasks[action.index].id);

      //delete in State
      let remainingTasks = state.tasks.filter((task, index) => {
        return index !== action.index;
      });

      //if no more tasks, currentTask = -1
      let updatedIndex = state.currentTask;
      if (remainingTasks.length === 0) {
        updatedIndex = -1;
      }
      return { ...state, tasks: remainingTasks, currentTask: updatedIndex };

    case "SELECT_TASK":
      return { ...state, tasks: [...state.tasks], currentTask: action.index };

    default:
      return state;
  }
};
