import { useState } from "react";
import Tasks from "./Tasks";
import Table from "./Table";
import { useTasksContext } from "../hooks/useTasksContext";
import { useFlowmodoroContext } from "../hooks/useFlowmodoroContext";

export default function TasksBox() {
  const [taskView, setTaskView] = useState(true);
  const [settingsOpen, setsettingsOpen] = useState(false);
  const {
    alarmSound,
    divisor,
    dispatch: flowmoDispatch,
  } = useFlowmodoroContext();
  const [divisorEdit, setDivisorEdit] = useState(divisor);
  const [alarmChoice, setAlarmChoice] = useState(alarmSound);
  const { tasks, dispatch } = useTasksContext();

  const handleAddTask = () => {
    dispatch({ type: "ADD_TASK" });
  };

  const handleOnClickSettings = () => {
    //toggle settings
    setsettingsOpen(!settingsOpen);

    //reset the edit to state value
    setDivisorEdit(divisor);
    setAlarmChoice(alarmSound);
  };

  const handleOnChangeDivisorInput = (e) => {
    if (e.target.value > 0 && e.target.value < 10) {
      setDivisorEdit(e.target.value);
    }
  };

  const handleOnChangeAlarmChoice = (e) => {
    setAlarmChoice(e.target.value);
  };

  const handleOnSubmit = () => {
    //update divisor
    flowmoDispatch({ type: "SET_DIVISOR", divisor: divisorEdit });
    //update alarm sound
    flowmoDispatch({ type: "SET_ALARMSOUND", alarm: alarmChoice });
    //close settings
    setsettingsOpen(false);
  };

  return (
    <>
      {settingsOpen && (
        <div className="settings-container">
          <div className="settings-box flexCol">
            <div className="flexRow closeRow">
              <h1>Settings</h1>
              <span
                className="material-symbols-outlined close-btn"
                onClick={handleOnClickSettings}
              >
                close
              </span>
            </div>

            <div className="flexRow break-input">
              <h2>break time = work time / </h2>
              <input
                type="number"
                min="1"
                max="9"
                step="1"
                value={divisorEdit}
                onChange={handleOnChangeDivisorInput}
                onClick={(e) => {
                  e.target.select();
                }}
              ></input>
            </div>

            <div className="flexRow alarm-input">
              <h2>alarm sound:</h2>
              <form>
                <input
                  type="radio"
                  id="egg"
                  name="alarm-sound"
                  value="egg"
                  checked={alarmChoice === "egg"}
                  onChange={handleOnChangeAlarmChoice}
                ></input>
                <label htmlFor="egg">egg timer</label>
                <br />
                <input
                  type="radio"
                  id="beep"
                  name="alarm-sound"
                  value="beep"
                  checked={alarmChoice === "beep"}
                  onChange={handleOnChangeAlarmChoice}
                ></input>
                <label htmlFor="beep">electronic beep</label>
                <br />
                <input
                  type="radio"
                  id="xylo"
                  name="alarm-sound"
                  value="xylo"
                  checked={alarmChoice === "xylo"}
                  onChange={handleOnChangeAlarmChoice}
                ></input>
                <label htmlFor="xylo">xylophone</label>
                <br />
              </form>
            </div>
            <button onClick={handleOnSubmit}>submit</button>
          </div>
        </div>
      )}
      <div className="task-container flexCol">
        <div className="flexRow tasks-title">
          <h2>Tasks</h2>
          <div className="more-btns">
            <i title={taskView ? "Full Table View" : "List View"}>
              <span
                className="material-symbols-outlined more"
                onClick={() => {
                  setTaskView(!taskView);
                }}
              >
                {taskView ? "table_chart" : "checklist"}
              </span>
            </i>
            <i title="Settings">
              <span
                className="material-symbols-outlined more"
                onClick={handleOnClickSettings}
              >
                settings
              </span>
            </i>
            {taskView && (
              <i title="Add Task">
                <span
                  className="material-symbols-outlined more"
                  onClick={handleAddTask}
                >
                  add
                </span>
              </i>
            )}
          </div>
        </div>
        <div className="line"></div>
        {taskView ? (
          <div className="tasks-box">
            {tasks &&
              tasks.map((task, index) => (
                <Tasks key={index} index={index} task={task}></Tasks>
              ))}
          </div>
        ) : (
          <div>
            <Table />
          </div>
        )}
      </div>
    </>
  );
}
