import { FlowmodoroContext } from "../contexts/FlowmodoroContext";
import { useContext } from "react";

export const useFlowmodoroContext = () => {
  const context = useContext(FlowmodoroContext);

  //check if we are in the scope of the context
  if (!context) {
    throw Error("useFlowmodoroContext must be used inside a FlowmodoroProvider");
  }
  return context;
};
