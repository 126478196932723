import { BrowserRouter } from "react-router-dom";
import Navbar from "./components/Navbar";
import Timer from "./components/Timer";
import TasksBox from "./components/TasksBox";
import Interruptions from "./components/Interruptions";
import EstimatedBreak from "./components/EstimatedBreak";
import { useTasksContext } from "./hooks/useTasksContext";
import { useFlowmodoroContext } from "./hooks/useFlowmodoroContext";

function App() {
  const { tasks, currentTask } = useTasksContext();
  const { isBreakTime } = useFlowmodoroContext();
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="body flexCol">
          <div className="page-container flexCol">
            <div className="flowmodoro-app flexCol">
              <h2 className="taskName">
                {currentTask < 0 || currentTask >= tasks.length ? (
                  <i>No Task Selected</i>
                ) : (
                  "Task " +
                  (currentTask + 1) +
                  " - " +
                  tasks[currentTask].taskName
                )}
              </h2>
              <div className="module-container flexRow">
                <Timer />
                {isBreakTime ? (
                  <div className="breakBox">
                    <div className="breaktime flexCol">
                      <h2>Break Time!</h2>
                      <p>
                        Get up, move around, or skip the break if you want.{" "}
                        <br />
                        ¯\_(ツ)_/¯
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="flexCol other-container">
                      <Interruptions />
                      <EstimatedBreak />
                    </div>
                  </>
                )}
              </div>
              <div className="taskbox-container">
                <TasksBox />
              </div>
            </div>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
