import Dexie from "dexie";

export const db = new Dexie("FlowmoDB");
db.version(1).stores({
  tasks: "++id, task",
  settings: "++id, divisor, alarmSound",
});

db.on("populate", function () {
  //init DB with default setting values:
  db.settings.add({ id: 0, divisor: 5, alarmSound: "egg" });
});
