import React from "react";
import { Task } from "../contexts/Task";
import { convertTimeToHrsMins } from "../helpers/Calculations";
import { useTasksContext } from "../hooks/useTasksContext";

export default function TaskTable({ task, taskIndex }) {
  const { dispatch } = useTasksContext();

  const handleClickDeleteFlowmo = (index) => {
    //delete flowmo in task
    dispatch({
      type: "DELETE_FLOWMO",
      flowmoIndex: index,
      taskIndex: taskIndex,
    });
  };

  return (
    <div className="task-table">
      <table>
        <tbody>
          <tr>
            <th></th>
            <th>#</th>
            <th>Work Time</th>
            <th>Break Time</th>
            <th>Interruptions</th>
            <th></th>
          </tr>

          {/* Dynamically create rows from task's flowmos */}
          {task.flowmodoros.map((flowmo, index) => {
            return (
              <tr key={flowmo.id} className="flowmo-row">
                <td>
                  <span
                    className="material-symbols-outlined cancel-btn"
                    onClick={() => {
                      handleClickDeleteFlowmo(index);
                    }}
                  >
                    cancel
                  </span>
                </td>
                <td>{index + 1}</td>
                <td>{convertTimeToHrsMins(flowmo.workTime)}</td>
                <td>{convertTimeToHrsMins(flowmo.breakTime)}</td>
                <td>{flowmo.interruptions}</td>

                <td></td>
              </tr>
            );
          })}

          <tr className="footer">
            <th>Total</th>
            <td>{task.flowmodoros.length}</td>
            <td>
              {convertTimeToHrsMins(Task.getTotalWorkTime(task.flowmodoros))}
            </td>
            <td>
              {convertTimeToHrsMins(Task.getTotalBreakTime(task.flowmodoros))}
            </td>
            <td>{Task.getTotalInterruptions(task.flowmodoros)}</td>

            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
